import {
  ColumnOrder,
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableSort,
  buildGroupsMap,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { STRINGS } from '../../../model/deliveries-v2.strings';
import { SortOrder } from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';
import {
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { COLUMNS_GROUPS } from 'src/app/shared/models/datatable/v2/columns-groups.model';
import { FilterTypeOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';

export const defaultSort: DatatableSort = {
  [STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.key]: {
    field: STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.filterKey,
    order: SortOrder.ASC,
  },
};

export const COLUMNS_ORDER: ColumnOrder = {
  [STRINGS.columns.deliveries.BUY_ORDER_ID.key]: 1,
  [STRINGS.columns.deliveries.NOTES.key]: 2,
  [STRINGS.columns.deliveries.SUPPLIER_NAME.key]: 3,
  [STRINGS.columns.deliveries.ICONS.key]: 4,
  [STRINGS.columns.deliveries.ORDERED.key]: 5,
  [STRINGS.columns.deliveries.DELIVERED.key]: 6,
  [STRINGS.columns.deliveries.TOTAL_VALUE.key]: 7,
  [STRINGS.columns.deliveries.DATE.key]: 8,
  [STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.key]: 9,
  [STRINGS.columns.deliveries.BUYER_EMAIL.key]: 10,
  [STRINGS.columns.deliveries.ACTIONS.key]: 11,
};

export const defaultColumnsV2: DatatableColumnV2Groups<DatatableColumnV2> = {
  [COLUMNS_GROUPS.ORDER.key]: {
    groupKey: COLUMNS_GROUPS.ORDER.key,
    groupName: COLUMNS_GROUPS.ORDER.name,
    columns: {
      [STRINGS.columns.deliveries.BUY_ORDER_ID.key]: {
        name: STRINGS.columns.deliveries.BUY_ORDER_ID.key,
        displayName: STRINGS.columns.deliveries.BUY_ORDER_ID.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.NOTES.key]: {
        name: STRINGS.columns.deliveries.NOTES.key,
        displayName: STRINGS.columns.deliveries.NOTES.name,
        checked: true,
        type: null,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.ORDERED.key]: {
        name: STRINGS.columns.deliveries.ORDERED.key,
        displayName: STRINGS.columns.deliveries.ORDERED.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.DELIVERED.key]: {
        name: STRINGS.columns.deliveries.DELIVERED.key,
        displayName: STRINGS.columns.deliveries.DELIVERED.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.TOTAL_VALUE.key]: {
        name: STRINGS.columns.deliveries.TOTAL_VALUE.key,
        displayName: STRINGS.columns.deliveries.TOTAL_VALUE.name,
        checked: true,
        type: FilterTypes.FILTER_NUMBER,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.key]: {
        name: STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.key,
        displayName: STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.name,
        checked: true,
        type: FilterTypes.FILTER_DATE,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.DATE.key]: {
        name: STRINGS.columns.deliveries.DATE.key,
        displayName: STRINGS.columns.deliveries.DATE.name,
        checked: true,
        type: FilterTypes.FILTER_DATE,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.BUYER_EMAIL.key]: {
        name: STRINGS.columns.deliveries.BUYER_EMAIL.key,
        displayName: STRINGS.columns.deliveries.BUYER_EMAIL.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.deliveries.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.deliveries.SUPPLIER_NAME.key,
        displayName: STRINGS.columns.deliveries.SUPPLIER_NAME.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: true,
        disabled: false,
      },
      [STRINGS.columns.deliveries.ICONS.key]: {
        name: STRINGS.columns.deliveries.ICONS.key,
        displayName: STRINGS.columns.deliveries.ICONS.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: false,
        disabled: false,
      },
    },
  },
  [COLUMNS_GROUPS.FIXED_END.key]: {
    groupKey: COLUMNS_GROUPS.FIXED_END.key,
    groupName: COLUMNS_GROUPS.FIXED_END.name,
    columns: {
      [STRINGS.columns.deliveries.ACTIONS.key]: {
        name: STRINGS.columns.deliveries.ACTIONS.key,
        displayName: STRINGS.columns.deliveries.ACTIONS.name,
        checked: true,
        type: FilterTypes.FILTER_TEXT,
        selectable: false,
        disabled: false,
      },
    },
  },
};

export const columnsGroupsMap: Map<string, string> =
  buildGroupsMap(defaultColumnsV2);

export const defaultFiltersV2: DatatableColumnV2Groups<Filter> = {
  [COLUMNS_GROUPS.ORDER.key]: {
    groupKey: COLUMNS_GROUPS.ORDER.key,
    groupName: COLUMNS_GROUPS.ORDER.name,
    columns: {
      [STRINGS.columns.deliveries.BUY_ORDER_ID.key]: {
        name: STRINGS.columns.deliveries.BUY_ORDER_ID.name,
        key: STRINGS.columns.deliveries.BUY_ORDER_ID.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.NOTES.key]: {
        name: STRINGS.columns.deliveries.NOTES.name,
        key: STRINGS.columns.deliveries.NOTES.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
        partialMatch: true,
      },
      [STRINGS.columns.deliveries.HAS_NOTES.key]: {
        name: STRINGS.columns.deliveries.HAS_NOTES.name,
        key: STRINGS.columns.deliveries.HAS_NOTES.filterKey,
        uiModel: FilterTypes.FILTER_NULLABLE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.NULLABLE,
          operator: FilterTypeOptionsV2.IS_NULL,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.ORDERED.key]: {
        name: STRINGS.columns.deliveries.ORDERED.name,
        key: STRINGS.columns.deliveries.ORDERED.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.DELIVERED.key]: {
        name: STRINGS.columns.deliveries.DELIVERED.name,
        key: STRINGS.columns.deliveries.DELIVERED.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 1,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.INTEGER,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.TOTAL_VALUE.key]: {
        name: STRINGS.columns.deliveries.TOTAL_VALUE.name,
        key: STRINGS.columns.deliveries.TOTAL_VALUE.filterKey,
        uiModel: FilterTypes.FILTER_NUMBER,
        validations: {
          min: 0,
          max: null,
          step: 0.01,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.FLOAT,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.key]: {
        name: STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.name,
        key: STRINGS.columns.deliveries.EXPECTED_DELIVERY_DATE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.DATE.key]: {
        name: STRINGS.columns.deliveries.DATE.name,
        key: STRINGS.columns.deliveries.DATE.filterKey,
        uiModel: FilterTypes.FILTER_DATE,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.DATETIME,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
      [STRINGS.columns.deliveries.BUYER_EMAIL.key]: {
        name: STRINGS.columns.deliveries.BUYER_EMAIL.name,
        key: STRINGS.columns.deliveries.BUYER_EMAIL.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        nullable: true,
        sharedFilter: true,
      },
    },
  },
  [COLUMNS_GROUPS.SUPPLIER.key]: {
    groupKey: COLUMNS_GROUPS.SUPPLIER.key,
    groupName: COLUMNS_GROUPS.SUPPLIER.name,
    columns: {
      [STRINGS.columns.deliveries.SUPPLIER_NAME.key]: {
        name: STRINGS.columns.deliveries.SUPPLIER_NAME.name,
        key: STRINGS.columns.deliveries.SUPPLIER_NAME.filterKey,
        uiModel: FilterTypes.FILTER_TEXT,
        validations: {
          min: null,
          max: null,
          step: null,
        },
        params: {
          valueType: ServiceRequestInfoV3FilteringType.STRING,
          operator: null,
          subOperator: ServiceRequestInfoV3Operators.AND,
          value: null,
        },
        disabled: false,
        sharedFilter: true,
      },
    },
  },
};

export const filtersGroupsMap: Map<string, string> =
  buildGroupsMap(defaultFiltersV2);

export const defaultPagination: DatatablePagination = {
  page: 0,
  size: 50,
  pageSizeOptions: [10, 15, 25, 50, 75, 100, 125, 150, 200],
};
