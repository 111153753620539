import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ResetDeliveriesV2State,
  SelectTabIndex,
} from '../actions/deliveries-shared.actions';
import { Injectable } from '@angular/core';

export interface DeliveriesV2StateModel {
  selectedTabIndex: number;
}

@State<DeliveriesV2StateModel>({
  name: 'deliveriesV2State',
  defaults: {
    selectedTabIndex: 0,
  },
})
@Injectable()
export class DeliveriesV2State {
  @Selector()
  static selectedTabIndex(state: DeliveriesV2StateModel) {
    return state.selectedTabIndex;
  }

  @Action(SelectTabIndex, { cancelUncompleted: true })
  selectTabIndex(
    ctx: StateContext<DeliveriesV2StateModel>,
    payload: SelectTabIndex
  ) {
    ctx.patchState({
      selectedTabIndex: payload.index,
    });
  }

  @Action(ResetDeliveriesV2State, { cancelUncompleted: true })
  resetDeliveriesV2State(ctx: StateContext<DeliveriesV2StateModel>) {
    ctx.patchState({
      selectedTabIndex: 0,
    });
  }
}
