import { DateTime } from 'luxon';
import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import { ServiceRequestInfoV3Operators } from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';

export const sharedCloseOrderDialogData = {
  title: $localize`Close order?`,
  body: $localize`Are you sure you want to <strong>close this order</strong>?<br>This action cannot be undone.`,
  actionName: $localize`Close order`,
  actionData: null,
};

export const expectedTodaysOrders: ColumnFilterParam = {
  columnKey: 'EXPECTED_DELIVERY_DATE',
  optionSelected: 'equals',
  subOperator: ServiceRequestInfoV3Operators.AND,
  values: {
    to: DateTime.now().toUTC().toJSDate(),
  },
  applyAll: false,
};

export const DELIVERIES_FILTERS = {
  OVERVIEW_SUMMARY_TODAYS_DELIVERIES: [expectedTodaysOrders],
};

export const DELIVERIES_TABS_INDEXES = {
  OVERVIEW_SUMMARY_OVERDUE_DELIVERIES: 0,
  OVERVIEW_SUMMARY_TODAYS_DELIVERIES: 1,
};
