import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { DateTime } from 'luxon';
import { catchError, concatMap, mergeMap, Observable, tap } from 'rxjs';
import {
  LoadFailed,
  SaveFailed,
  SaveSucceed,
} from 'src/app/core/actions/app.action';
import { WebshopState } from 'src/app/core/states/webshop.state';
import { Pageable } from 'src/app/shared/components/data-table-v2/model/pageable.model';
import {
  AddFilterParam,
  AddFilterParamNoReload,
  AddSearchParam,
  CloseExpectedOrderDialog,
  CloseOrder,
  ColumnsSelected,
  HideColumn,
  InitializeExpectedOrdersState,
  LoadExpectedOrders,
  Paginate,
  ReloadDatatable,
  RemoveAllFilters,
  RemoveSearchParam,
  ResetPagination,
  ResetPaginationAndLoadData,
  Sort,
  ToggleFilter,
  UpdateExpectedDeliveryDate,
} from '../actions/expected-orders.actions';
import {
  columnsGroupsMap,
  defaultColumnsV2,
  defaultFiltersV2,
  defaultPagination,
  defaultSort,
  filtersGroupsMap,
} from '../model/data-table.model';
import {
  DeliveriesBuyOrders,
  Delivery,
} from 'src/app/shared/models/delivery/v2/deliveries-v2.model';
import { DeliveriesV2Service } from 'src/app/core/api/deliveries/v2/deliveries-v2.service';
import { AccountSettingsState } from 'src/app/core/states/account-settings.state';
import { append, patch, removeItem } from '@ngxs/store/operators';
import { AccountSettingsUpdate } from 'src/app/shared/models/account/account-settings.model';
import { AccountState } from 'src/app/core/states/account.state';
import { AccountSettingsService } from 'src/app/core/api/account/v2/account-settings.service';
import { LoadAccountSettings } from 'src/app/core/actions/settings.action';
import {
  DatatableState,
  DatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/state/data-table.state';
import {
  DatatableColumnV2,
  DatatableColumnV2Groups,
  DatatablePagination,
  DatatableParam,
  defaultDatatableStateModel,
} from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';
import {
  Sorted,
  SortedOrder,
} from 'src/app/shared/components/design-system/data-table-v2/components/sort/model/sort.model';
import {
  ServiceRequestInfoV3,
  ServiceRequestInfoV3FilteringType,
  ServiceRequestInfoV3Operators,
} from 'src/app/shared/components/design-system/data-table-v2/model/pageable-v2.model';
import { FilterTypesOptionsV2 } from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter-v2.model';
import { WebshopSelected } from 'src/app/core/actions/webshop.action';
import {
  AddSharedFilterParam,
  AddSharedSearchParam,
  RemoveAllSharedFilters,
  RemoveSharedSearchParam,
  ToggleSharedFilter,
} from '../../../actions/deliveries-shared.actions';
import {
  Filter,
  FilterTypes,
} from 'src/app/shared/components/design-system/data-table-v2/components/filter/model/filter.model';

export interface ExpectedOrdersStateModel extends DatatableStateModel {
  orders: Delivery[];
  page: Pageable;
  loading: boolean;
  orderBeingUpdated: string | null;
}

@State<ExpectedOrdersStateModel>({
  name: 'expectedOrdersState',
  defaults: {
    ...defaultDatatableStateModel,
    pagination: defaultPagination,
    columnsGroups: defaultColumnsV2,
    filtersGroups: defaultFiltersV2,
    sortBy: defaultSort,
    search: null,
    filterOpened: false,
    orders: [],
    page: { totalElements: 0 },
    loading: false,
    orderBeingUpdated: null,
  },
})
@Injectable()
export class ExpectedOrdersState extends DatatableState {
  static filterDataByColumnKey(columnKey: string) {
    return createSelector(
      [ExpectedOrdersState],
      (state: ExpectedOrdersStateModel) => {
        const groupKey = filtersGroupsMap.get(columnKey);

        if (!groupKey) {
          throw new Error(`Column group ${groupKey} does not exist`);
        }

        if (state.filtersGroups[groupKey].columns[columnKey] === undefined) {
          throw new Error(`Column ${columnKey} does not exist`);
        }

        const sortBy = new Map(Object.entries(state.sortBy));

        return {
          columnKey,
          filter: state.filtersGroups[groupKey].columns[columnKey],
          filtered:
            !!state.filtersGroups[groupKey].columns[columnKey].params.value,
          sorted: sortBy.has(
            state.filtersGroups[groupKey].columns[columnKey]?.key
          )
            ? (sortBy.get(state.filtersGroups[groupKey].columns[columnKey].key)
                .order as SortedOrder)
            : null,
        };
      }
    );
  }

  @Selector()
  static orders(state: ExpectedOrdersStateModel): Delivery[] {
    return state.orders;
  }

  constructor(
    private store: Store,
    private deliveriesV2Service: DeliveriesV2Service,
    private accountSettingsService: AccountSettingsService
  ) {
    super();
  }

  @Action(InitializeExpectedOrdersState, { cancelUncompleted: true })
  initialize(ctx: StateContext<ExpectedOrdersStateModel>) {
    const tableSettings = this.store.selectSnapshot(
      AccountSettingsState.futureDeliveriesTableSettings
    );

    const columns = this.initializeColumnsFromPersistence(
      ctx.getState().columnsGroups,
      tableSettings.excludedColumns
    );

    ctx.setState(
      patch<ExpectedOrdersStateModel>({
        columnsGroups: columns,
        pagination: patch<DatatablePagination>({
          size: tableSettings.pageSize,
        }),
      })
    );

    return ctx.dispatch(new LoadExpectedOrders());
  }

  @Action(LoadExpectedOrders, { cancelUncompleted: true })
  loadExpectedOrders(ctx: StateContext<ExpectedOrdersStateModel>) {
    ctx.patchState({
      loading: true,
    });

    return this._fetchOpenFutureOrders(ctx).pipe(
      tap((orders: DeliveriesBuyOrders) => {
        ctx.patchState({
          loading: false,
          orders: orders.data,
          page: { totalElements: orders.metadata.page.totalElements },
        });
      })
    );
  }

  @Action(ReloadDatatable)
  reloadDatatable(ctx: StateContext<ExpectedOrdersStateModel>) {
    return ctx.dispatch(new LoadExpectedOrders());
  }

  @Action(ReloadDatatable)
  handleRefresh(ctx: StateContext<ExpectedOrdersStateModel>) {
    return this.disableRefresh(ctx);
  }

  @Action(Paginate, { cancelUncompleted: true })
  paginate(ctx: StateContext<ExpectedOrdersStateModel>, payload: Paginate) {
    const state = ctx.getState();

    const didPageSizeChange =
      ctx.getState().pagination.size !== payload.pagination.pageSize;

    if (didPageSizeChange) {
      return this._saveTableSizePersistance(
        ctx,
        payload.pagination.pageSize
      ).pipe(
        tap(() => {
          ctx.patchState({
            pagination: {
              ...state.pagination,
              page: payload.pagination.pageIndex,
              size: payload.pagination.pageSize,
            },
          });
        }),
        mergeMap(() => ctx.dispatch(new LoadExpectedOrders())),
        mergeMap(() =>
          ctx.dispatch(
            new LoadAccountSettings(
              this.store.selectSnapshot(AccountState.userUuid)
            )
          )
        )
      );
    }

    ctx.patchState({
      pagination: {
        ...state.pagination,
        page: payload.pagination.pageIndex,
        size: payload.pagination.pageSize,
      },
    });

    return ctx.dispatch(new LoadExpectedOrders());
  }

  @Action(UpdateExpectedDeliveryDate, { cancelUncompleted: true })
  updateExpectedDeliveryDate(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: UpdateExpectedDeliveryDate
  ) {
    ctx.patchState({
      orderBeingUpdated: payload.buyOrderUUID,
    });

    return this._updateExpectedDeliveryDate(ctx, payload).pipe(
      concatMap(() => ctx.dispatch(new SaveSucceed())),
      concatMap(() => ctx.dispatch(new LoadExpectedOrders())),
      tap(() => {
        ctx.patchState({
          orderBeingUpdated: null,
        });
      })
    );
  }

  @Action(CloseOrder, { cancelUncompleted: true })
  closeOrder(ctx: StateContext<ExpectedOrdersStateModel>, payload: CloseOrder) {
    return this._saveCompleteDeliveries(ctx, payload.orderUUID).pipe(
      concatMap(() =>
        ctx.dispatch([new SaveSucceed(), new CloseExpectedOrderDialog()])
      ),
      concatMap(() => ctx.dispatch(new LoadExpectedOrders()))
    );
  }

  @Action([AddFilterParam, AddSharedFilterParam], { cancelUncompleted: true })
  addFilterParam(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: AddFilterParam
  ) {
    const groupKey = filtersGroupsMap.get(payload.param.columnKey);

    if (!groupKey) return;

    this.addGroupParam(ctx, groupKey, payload.param.columnKey, {
      operator: payload.param.optionSelected as FilterTypesOptionsV2,
      subOperator: payload.param.subOperator,
      value: payload.param.values,
    });

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([AddSearchParam, AddSharedSearchParam], { cancelUncompleted: true })
  addSearchParam(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: AddSearchParam
  ) {
    ctx.setState(
      patch<ExpectedOrdersStateModel>({
        search: append<string>([payload.param]),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action([RemoveSearchParam, RemoveSharedSearchParam], {
    cancelUncompleted: true,
  })
  removeSearchParam(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: RemoveSearchParam
  ) {
    ctx.setState(
      patch<ExpectedOrdersStateModel>({
        search: removeItem<string>(
          searchParam => searchParam === payload.param
        ),
      })
    );

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(Sort, { cancelUncompleted: true })
  sortTable(ctx: StateContext<ExpectedOrdersStateModel>, payload: Sort) {
    return this.sort(ctx, payload.sort);
  }

  @Action(ColumnsSelected, { cancelUncompleted: true })
  columnsSelected(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: ColumnsSelected
  ) {
    return this._saveTableColumnsPersistance(
      ctx,
      payload.selection.columnsGroups
    ).pipe(
      tap(() => {
        ctx.setState(
          patch({
            columnsGroups: {
              ...ctx.getState().columnsGroups,
              ...payload.selection.columnsGroups,
            },
          })
        );
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action(HideColumn, { cancelUncompleted: true })
  hideColumn(ctx: StateContext<ExpectedOrdersStateModel>, payload: HideColumn) {
    const groupKey = columnsGroupsMap.get(payload.columnKey);

    if (!groupKey) return;

    const newColumnSelection = this.hideGroupColumnFromColumnKey(
      ctx.getState().columnsGroups,
      groupKey,
      payload.columnKey
    );

    return this._saveTableColumnsPersistance(ctx, newColumnSelection).pipe(
      tap(() => {
        ctx.patchState({
          columnsGroups: newColumnSelection,
        });
      }),
      mergeMap(() =>
        ctx.dispatch(
          new LoadAccountSettings(
            this.store.selectSnapshot(AccountState.userUuid)
          )
        )
      )
    );
  }

  @Action([RemoveAllFilters, RemoveAllSharedFilters], {
    cancelUncompleted: true,
  })
  removeAllFilters(ctx: StateContext<ExpectedOrdersStateModel>) {
    this._removeAllFilters(ctx);

    return ctx.dispatch(new ResetPaginationAndLoadData());
  }

  @Action(WebshopSelected)
  removeAllFiltersOnWebshopChange(ctx: StateContext<ExpectedOrdersStateModel>) {
    this._removeAllFilters(ctx);
  }

  @Action([ToggleFilter, ToggleSharedFilter], { cancelUncompleted: true })
  toggleFilter(ctx: StateContext<ExpectedOrdersStateModel>) {
    ctx.patchState({
      filterOpened: !ctx.getState().filterOpened,
    });
  }

  @Action(ResetPagination)
  resetPagination(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: ResetPagination
  ) {
    ctx.patchState({
      pagination: {
        ...ctx.getState().pagination,
        page: payload.page,
      },
    });
  }

  @Action(ResetPaginationAndLoadData, { cancelUncompleted: true })
  resetPaginationAndLoadData(ctx: StateContext<ExpectedOrdersStateModel>) {
    return ctx.dispatch([new ResetPagination(), new LoadExpectedOrders()]);
  }

  @Action(AddFilterParamNoReload, { cancelUncompleted: true })
  addFilterParamNoReload(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: AddFilterParamNoReload
  ) {
    if (!payload.param || payload.param?.length === 0) return;

    payload.param.forEach(element => {
      const groupKey = filtersGroupsMap.get(element.columnKey);

      if (!groupKey) return;

      this.addGroupParam(ctx, groupKey, element.columnKey, {
        operator: element.optionSelected as FilterTypesOptionsV2,
        subOperator: element.subOperator,
        value: element.values,
      });
    });

    if (payload.reload) {
      return ctx.dispatch(new ResetPaginationAndLoadData());
    }
  }

  override addGroupParam(
    ctx: StateContext<ExpectedOrdersStateModel>,
    groupKey: string,
    columnKey: string,
    param: DatatableParam
  ): void {
    ctx.setState(
      patch<ExpectedOrdersStateModel>({
        filtersGroups: patch({
          [groupKey]: patch({
            columns: patch({
              [columnKey]: patch({
                params: patch<DatatableParam>({
                  ...param,
                  operator:
                    param.operator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .operator,
                  subOperator:
                    param.subOperator ??
                    defaultFiltersV2[groupKey].columns[columnKey].params
                      .subOperator,
                }),
              }),
            }),
          }),
        }),
      })
    );
  }

  sort(ctx: StateContext<ExpectedOrdersStateModel>, sort: Sorted): void {
    ctx.patchState({
      sortBy: {
        [sort.key]: {
          field: sort.key,
          order: sort.order,
        },
      },
    });

    ctx.dispatch(new LoadExpectedOrders());
  }

  private _removeAllFilters(ctx: StateContext<ExpectedOrdersStateModel>): void {
    ctx.patchState({
      filtersGroups: defaultFiltersV2,
      search: [],
      sortBy: defaultSort,
    });
  }

  private _fetchOpenFutureOrders(
    ctx: StateContext<ExpectedOrdersStateModel>
  ): Observable<DeliveriesBuyOrders | void> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    const requestInfo: ServiceRequestInfoV3 =
      this._buildFetchExpectedOrdersRequestInfo(ctx.getState());

    return this.deliveriesV2Service
      .fetchBuyOrders(webshopUuid, requestInfo)
      .pipe(
        catchError(() => {
          ctx.patchState({
            loading: false,
            orders: [],
            page: { totalElements: 0 },
          });

          return ctx.dispatch(new LoadFailed());
        })
      );
  }

  private _buildFetchExpectedOrdersRequestInfo(
    state: ExpectedOrdersStateModel
  ): ServiceRequestInfoV3 {
    const filtersWithoutGroups: Filter[] = Object.values(state.filtersGroups)
      .map(group => Object.values(group.columns))
      .flat();

    filtersWithoutGroups.push({
      name: null,
      key: 'STATUS',
      uiModel: FilterTypes.FILTER_TEXT,
      validations: {
        min: null,
        max: null,
        step: null,
      },
      params: {
        valueType: ServiceRequestInfoV3FilteringType.STRING,
        operator: null,
        subOperator: ServiceRequestInfoV3Operators.AND,
        value: { to: 'EXPECTED' },
      },
      disabled: false,
    });

    const requestInfo: ServiceRequestInfoV3 = {
      queryData: {
        filters: this.buildFiltersV2(filtersWithoutGroups),
        sort_by: this.buildSortBy(state.sortBy),
        page: {
          from: state.pagination.page,
          size: state.pagination.size,
        },
      },
    };

    if (state.search !== null && !!state.search?.length) {
      requestInfo.queryData.query = this.buildQuery(state.search);
    }

    return requestInfo;
  }

  private _updateExpectedDeliveryDate(
    ctx: StateContext<ExpectedOrdersStateModel>,
    payload: UpdateExpectedDeliveryDate
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.deliveriesV2Service
      .updateExpectedDeliveryDate(webshopUuid, payload.buyOrderUUID, {
        expectedDeliveryDate: DateTime.fromJSDate(
          payload.deliveryDate
        ).toFormat('yyyy-MM-dd'),
      })
      .pipe(
        catchError(() => {
          return ctx.dispatch(new SaveFailed());
        })
      );
  }

  private _saveCompleteDeliveries(
    ctx: StateContext<ExpectedOrdersStateModel>,
    orderUuid: string
  ): Observable<any> {
    const webshopUuid = this.store.selectSnapshot(WebshopState.selected).uuid;

    return this.deliveriesV2Service
      .saveAndCompleteOrder(webshopUuid, orderUuid)
      .pipe(
        catchError(() => {
          return ctx.dispatch([
            new CloseExpectedOrderDialog(),
            new SaveFailed(),
          ]);
        })
      );
  }

  private _saveTableColumnsPersistance(
    ctx: StateContext<ExpectedOrdersStateModel>,
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveColumnPersistanceRequestInfo(columnsGroups);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveColumnPersistanceRequestInfo(
    columnsGroups: DatatableColumnV2Groups<DatatableColumnV2>
  ): AccountSettingsUpdate {
    const excludedColumns: string[] = this.buildExcludedColumns(columnsGroups);

    return {
      futureDeliveriesTableExcludedColumns: excludedColumns,
      overrideFutureDeliveriesTableExcludedColumns: true,
    };
  }

  private _saveTableSizePersistance(
    ctx: StateContext<ExpectedOrdersStateModel>,
    newPageSize: number
  ): Observable<any> {
    const requestInfo: AccountSettingsUpdate =
      this._buildSaveTableSizePersistanceRequestInfo(newPageSize);

    return this._updateAccountSettings(ctx, requestInfo);
  }

  private _buildSaveTableSizePersistanceRequestInfo(
    newPageSize: number
  ): AccountSettingsUpdate {
    return {
      futureDeliveriesTablePageSize: newPageSize,
    };
  }

  private _updateAccountSettings(
    ctx: StateContext<ExpectedOrdersStateModel>,
    requestInfo: AccountSettingsUpdate
  ): Observable<any> {
    const userUuid = this.store.selectSnapshot(AccountState.userUuid);

    return this.accountSettingsService
      .updateSettings(userUuid, requestInfo)
      .pipe(
        catchError(e => {
          ctx.dispatch(new SaveFailed());

          throw new Error(e.message || e);
        })
      );
  }
}
