import { ColumnFilterParam } from 'src/app/shared/components/design-system/data-table-v2/model/data-table-v2.model';

export class SelectTabIndex {
  static readonly type = '[Deliveries Overviews Shared] Select Tab Index';

  constructor(public index: number) {}
}

export class ResetDeliveriesV2State {
  static readonly type = '[Deliveries Overviews Shared] Reset State';
}

export class ToggleSharedFilter {
  static readonly type = '[Deliveries Overviews Shared] Toggle Shared Filter';
}

export class AddSharedSearchParam {
  static readonly type =
    '[Deliveries Overviews Shared] Add Shared Search Param';

  constructor(public param: string | null) {}
}

export class RemoveSharedSearchParam {
  static readonly type =
    '[Deliveries Overviews Shared] Remove Shared Search Param';

  constructor(public param: string | null) {}
}

export class AddSharedFilterParam {
  static readonly type =
    '[Deliveries Overviews Shared] Add Shared Filter Param';

  constructor(public param: ColumnFilterParam) {}
}

export class RemoveAllSharedFilters {
  static readonly type =
    '[Deliveries Overviews Shared] Remove All Shared Filters';
}
